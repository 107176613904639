/* DirectAccess.css */

/* General Container Styling */
#direct-access-container {
    padding: 100px 20px 50px; /* Adds padding to the section */
    background-color: #f4f4f4;
    text-align: left;
}

/* Content Layout */
.direct-access-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.direct-access-image {
    flex: 1 1 40%;
    padding-right: 20px;
}

    .direct-access-image img {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

.direct-access-text {
    flex: 1 1 60%;
    padding-left: 20px;
}

    .direct-access-text h2 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .direct-access-text p {
        font-size: 1.2rem;
        color: #666;
        margin-bottom: 20px;
    }

/* Schedule button styling */
.schedule-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333; /* Dark background color */
    color: #fff; /* White text */
    text-decoration: none; /* Removes underline */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

    .schedule-button:hover {
        background-color: #fff; /* Light background on hover */
        color: #333; /* Dark text on hover */
        border: 1px solid #333; /* Border on hover */
    }

/* FAQ Section Styling */
/*.faq-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}*/

    .faq-section h3 {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 20px;
    }

.faq {
    max-width: 800px;
    margin: 0 auto;
}

.faq-question {
    width: 100%;
    text-align: left;
    padding: 15px;
    background-color: #f4f4f4;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .faq-question:hover {
        background-color: #e4e4e4;
    }

.faq-answer {
    padding: 15px;
    background-color: #fff;
    font-size: 1rem;
    color: #666;
}

.faq-question:focus {
    outline: none;
}

/* Card Styling */
.card {
    border: none;
    margin-bottom: 10px;
}

.card-body {
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 15px;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .direct-access-content {
        flex-direction: column;
        text-align: center;
    }

    .direct-access-image {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .direct-access-text {
        padding-left: 0;
    }

    .faq-question {
        font-size: 1rem;
    }

    .faq-answer {
        font-size: 0.9rem;
    }
}
