#contact {
    padding: 60px 20px;
    /*background-color: #e4e4df; A light neutral background */
    text-align: left;
}

.contact-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px;
    border-radius: 10px;
   
}

.contact-info {
    flex: 1;
    padding-right: 40px;
}

.contact-info h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #3d3d3d;
}

.contact-info p {
    margin-bottom: 20px;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
}

.contact-details p {
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #666;
}

.contact-form {
    flex: 1;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.contact-form label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

.name-fields {
    display: flex;
    gap: 20px;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 12px;
    border-radius: 20px; /* Rounded corners */
    border: 2px solid black; /* Black border */
    background-color: transparent; /* No white background */
    font-size: 1rem;
    color: black; /* Text color */
    margin-top: 5px;
    transition: border-color 0.3s, box-shadow 0.3s; /* Transition for focus effect */
}

.contact-form input:focus, .contact-form textarea:focus {
    border-color: black; /* Black border on focus */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for focus effect */
    outline: none; /* Removes default focus outline */
}

.contact-form .form-group input::placeholder,
.contact-form .form-group textarea::placeholder {
    color: #666; /* Placeholder text color */
}

.contact-form input::-webkit-autofill,
.contact-form textarea::-webkit-autofill {
    background-color: transparent !important; /* Ensures autofill doesn’t apply white */
}


.contact-button {
    background-color: transparent; /* No background */
    color: black; /* Text color */
    padding: 12px 25px;
    border: 2px solid black; /* Border to match inputs */
    border-radius: 20px; /* Rounded corners */
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition */
}

.contact-button:hover {
    background-color: black; /* Black background on hover */
    color: white; /* White text on hover */
    border-color: black; /* Keeps border black on hover */
}


.alert-banner {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 20px;
    display: block;
}
/* Responsive Styles */
@media (max-width: 768px) {
    .contact-content {
        flex-direction: column; /* Stack elements vertically */
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .contact-info h2 {
        font-size: 2rem; /* Smaller font size for headings */
        text-align: center; /* Center align for better readability */
    }

    .contact-info p {
        text-align: center; /* Center align the descriptive text */
        font-size: 1rem; /* Adjust text size for mobile */
    }

    .contact-details p {
        text-align: center; /* Center align contact details */
        font-size: 1rem;
    }

    .contact-form {
        width: 100%; /* Full width form on mobile */
        margin-top: 20px; /* Add space above the form */
    }

    .name-fields {
        flex-direction: column; /* Stack first and last name vertically */
        gap: 10px; /* Reduce gap between the fields */
    }

    .contact-form .form-group {
        margin-bottom: 15px; /* Reduce space between form fields */
    }

    .contact-button {
        width: 100%; /* Full width button on mobile */
        font-size: 1rem; /* Adjust button font size */
        padding: 10px; /* Adjust padding for smaller screens */
    }
}
