.footer {
    background-color: #222;
    color: #fff;
    padding: 40px 20px;
    text-align: left;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    min-width: 250px;
    margin: 20px 0;
  }
  
  .footer-column h4 {
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: #f4f4f4;
  }
  
  .footer-column p {
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
  }
  
  .footer-column ul li a {
    color: #f4f4f4;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-column ul li a:hover {
    color: #007bff;
  }
  
  .footer-social-icons a {
    color: #fff;
    margin-right: 15px;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .footer-social-icons a:hover {
    color: #007bff;
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    padding-top: 20px;
    text-align: center;
    margin-top: 40px;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
    color: #aaa;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
    }
  
    .footer-column {
      margin-bottom: 30px;
    }
  
    .footer-social-icons {
      justify-content: center;
    }
  }
  