/* index.css */

/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Libre Baskerville', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #DED8D4;
}

code {
  font-family: 'Courier New', Menlo, Monaco, Consolas, monospace;
}
