#physical-therapy-services {
    padding: 100px 20px 50px; /* Adds padding to the section */
    background-color: #f9f9f9;
    text-align: center; /* Centers the text */
}

.intro h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
}

.divider {
    width: 100px;
    height: 5px;
    background-color: black; /* Blue color for the divider */
    margin: 20px auto;
    border-radius: 3px;
}

.services-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns layout */
    gap: 40px; /* Space between items */
    margin-top: 40px;
}

.service-item h2 {
    font-size: 1.5rem;
    /*color: #0066cc;*/ /* Blue color for headings */
    margin-bottom: 10px;
    text-align: left; /* Aligns the heading to the left */
}

.service-item p {
    font-size: 1rem;
    color: #333;
    text-align: left; /* Aligns the text to the left */
    line-height: 1.6; /* Improves readability */
}

@media (max-width: 768px) {
    .services-list {
        grid-template-columns: 1fr; /* Switches to single column on smaller screens */
    }
}

.accordion {
    margin-top: 40px;
}

.accordion-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    cursor: pointer;
    font-size: 1.2rem;
}

    .accordion-title h3 {
        margin: 0;
        font-size: 1.5rem;
        /*        color: #0066cc;*/
    }

    .accordion-title span {
        font-size: 1.5rem;
    }

.accordion-content {
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #ddd;
}

.accordion-content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three equal columns */
    gap: 10px; /* Space between columns */
}

.accordion-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
}

.accordion-content li {
    margin-bottom: 5px;
    text-align: left;
}

@media (max-width: 768px) {
    .accordion-content-grid {
        grid-template-columns: 1fr; /* Stacks columns on smaller screens */
    }
}
