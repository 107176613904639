#meet-the-team {
    padding: 100px 20px 50px; /* Adds padding to the section */
    text-align: center;
}

    #meet-the-team h2 {
        font-size: 2.5rem;
        margin-bottom: 40px;
        color: #333;
    }

.team-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
}

.team-member {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
}

.team-member-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 20px;
}

.team-member h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #0056b3;
}

.team-member-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

.team-member-bio {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
}

.team-member-quote {
    font-style: italic;
    color: #888;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .team-grid {
        flex-direction: column;
        align-items: center;
    }
}
